.fondo-contacto {
    background: url(../assets/ondaContacto.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.logoBlackLion-contacto {
    position: absolute;
    width: 9.5%;
    right: 9vw;
    top: 6vw;
    z-index: 2;
}

.icono-cerrar {
    position: absolute;
    left: 3vw;
    top: 3vw;
    width: 3%;
    z-index: 2;
    cursor: pointer;
    padding: 0 2vw;
}

.contenedor-contacto {
    padding: 20vw 10vw 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contenedor-contacto-hijo{
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.imagenFoot{
    display: none;
}

.otroFondo {
    padding: 20vw 10vw 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#contactanos {
    padding-left: 1vw;
}

#texto-contacto {
    margin-top: 1.5vw;
    padding-left: 1vw;
}

#escribenos {
    margin-top: 7vw;
    margin-bottom: 3vw;
    padding-left: 1vw;
}

.formulario {
    width: 100%;
}

.fila-contacto {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.columna-campo {
    margin-bottom: 1vw;
    width: 47%;
    display: flex;
    padding: 0.5vw 1vw;
    flex-direction: column;
    align-items: flex-start;
}

.input-contacto {
    width: 90%;
    margin-top: 0.5vw;
    border-radius: 10vw;
    height: 3.5vw;
    border: 1px solid #0d294eab;
    background: none;
    font-size: 1.5vw;
    color: #0d294e;
    padding: 0 1.5vw;
    font-family: MultiVariable;
}

.input-contacto:focus {
    border-color: #173373d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #17337359;
}

.columna-completa-campo {
    margin-bottom: 1vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1vw 0.5vw;
}

#select-tipo {
    width: 99%;
    font-family: MultiVariable;
}

#input-mensaje {
    resize: none;
    padding-top: 2vw;
    padding-bottom: 2vw;
    border-radius: 2.5vw;
    height: 20vw;
    width: 93%;
    font-family: MultiVariable;
}

.btn-enviar {
    background: linear-gradient(90deg, #e87096 0%, #2d2e88 100%);
    color: white;
    margin: 2vw 0 1vw 1vw;
    width: 20%;
    height: 4vw;
    border-radius: 12vw;
    cursor: pointer;
    border: 0;
}

.pad {
    padding-top: 1vw;
}

.btn-enviar:focus {
    border-color: #173373d6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #17337359;
}

@media only screen and (max-width: 768px) {

    .logoBlackLion-contacto {
        position: absolute;
        width: 25%;
        left: 37.5%;
        right: 37.5%;
        top: 10vw;
    }

    .pad {
        padding-top: 1.5vw;
    }

    .fondo-contacto {
        background: url(../assets/ondaContatoMovil.svg);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position-y: 3%;
        position: relative;
        /* background-color: #dadada; */
    }

    .icono-cerrar {
        position: absolute;
        left: 3vw;
        top: 5vw;
        width: 5%;
        z-index: 2;
        cursor: pointer;
    }

    .imagenFoot{
        display: block;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .contenedor-contacto-hijo{
        padding: 0vw 10% 0;
        align-items: center;
        width: 80%;
        background-color: #dadada;
        padding-bottom: 20vw;
    }

    .contenedor-contacto {
        padding: 42vw 0 0;
        align-items: center;
        /* background-color: #dadada; */
    }

    #texto-contacto {
        margin-top: 3vw;
        text-align: center;
    }

    #escribenos {
        margin-top: 10vw;
        margin-bottom: 5vw;
    }

    .fila-contacto {
        flex-direction: column;
        align-items: center;
    }

    .formulario {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .columna-campo {
        width: 100%;
    }

    #select-tipo {
        width: 97%;
    }

    .input-contacto {
        margin: 2vw 1vw 0.5vw;
        height: 8vw;
        width: 85%;
        padding: 0 4vw 0;
        font-size: 4vw;
    }

    #input-mensaje {
        height: 30vw;
        width: 85%;
        margin: 2vw 0.5vw;
    }

    #btn-enviar-con {
        align-self: center;
        width: 45%;
        height: 10vw;
        font-size: 4vw;
        margin: 4vw 0;
        border: 0;
    }
}