.terminos-condiciones{
  display: flex;
  background-color: #D9D9D9;
  flex-direction: column;
  position: relative;
  padding: 15rem 5rem 20rem;
}

.titulo-terminos {
  margin-bottom: 3rem;
}

.texto-terminos {
  font-size: 1.5vw;
}

@media only screen and (max-width: 768px) {
  .terminos-condiciones{
    padding-top: 7rem;
  }

  .titulo-terminos {
    margin-bottom: 1.5rem;
  }

  .texto-terminos {
    font-size: 3.5vw;
    text-align: center;
    padding-bottom: 8rem;
  }
}