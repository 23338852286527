$color-texto: #0d294e
$color-rosado: rgb(206, 39, 128)

.web
  display: flex

.info-cuenta
  font-size: 1.5vw
  display: flex
  justify-content: space-between
  color: $color-texto
  padding: 0 2rem
  width: 100%
  align-items: center

.dialogo-agregar-cuenta
  position: absolute
  background-color: white
  display: flex
  flex-direction: column
  align-items: center
  z-index: 100
  border-radius: 2vw
  padding: 1rem 0

  .titulo-agregar-cuenta
    font-size: 2.1vw
    font-weight: bold
    color: $color-texto
    margin: 1rem 0 2rem
    font-family: "NexaBlack"

  .fila
    display: flex
    justify-content: center
    align-items: center
    width: 100%

  .form-sesion
    width: 70%
    display: flex
    flex-direction: column

  .dialogo-banco
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

  button
    width: 100%
    background: none
    border: solid 0.15vw var(--colorFuxia)
    border-radius: 2vw
    color: var(--colorFuxia)
    font-weight: bold
    font-size: 1vw
    display: flex
    justify-content: center
    align-items: center
    padding: 0 1vw
    height: 2vw
    outline: none
    cursor: pointer
    margin: 0.5vw 0
    align-self: flex-end

  .form-cuenta
    margin-top: 2rem
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(5, 1fr)
    width: 90%
    position: relative

    .contenedor-campo
      display: flex
      flex-direction: column

      .label-form
        font-size: 1.3vw
        margin-bottom: 0.5vw
        font-family: Muli-bold
        padding-left: 2rem
        color: $color-texto
        overflow-y: hidden

      .input-sesion
        margin: 0 1rem 1rem 1rem
        border-radius: 2rem
        padding: 0 1.2vw
        font-size: 1.2vw
        font-family: MultiVariable
        border: 1px solid rgb(186, 186, 186)
        color: $color-texto
        overflow-y: hidden

      .input-sesion:focus
        border-color: $color-rosado
        outline: 0
        box-shadow: 0 0 0 0.2rem rgba(206, 39, 128, 0.37)

      .select-input
        padding: 0.4rem 1.2vw

    .img-atras-cuenta
      width: 3%
      padding: 0 1rem
      cursor: pointer
      position: absolute
      bottom: 0
      left: 0

  .btn-enviar
    width: 70%
    margin-left: auto
    margin-right: 2rem

  .btn-agregar
    margin: 0
    width: 30%

  .btn-terminos
    width: 45%
    margin-left: auto
    margin-right: 2rem

.dialogo-nuevo-pago
  width: 60%
  right: auto
  margin-right: 0
  padding: 1rem 0 3rem

.dialogo-nueva-cuenta
  width: 50%

@media only screen and (max-width: 768px)
  .web
    display: none

  .info-cuenta
    flex-direction: column
    align-items: flex-start
    padding: 0 1rem
    font-size: 3.5vw

  .dialogo-agregar-cuenta

    .botones-dialogo
      align-self: flex-end
      margin-top: 1rem

    .form-sesion
      width: 85%

    button
      font-size: 3vw
      padding: 0.7rem 1rem
      border-radius: 5vw
      margin: 2vw 0 0

    .form-cuenta
      margin-top: 1rem
      grid-template-columns: repeat(1, 1fr)
      grid-template-rows: repeat(6, 1fr)

      .contenedor-campo

        .label-form
          font-size: 3.5vw

        .input-sesion
          margin: 0 1rem 0.5rem 1rem
          border-radius: 2rem
          padding: 0.1rem 1rem
          font-size: 3.3vw

      .img-atras-cuenta
        width: 4%
        padding: 0.7rem 1rem

    .titulo-agregar-cuenta
      font-size: 5vw

    .btn-enviar
      font-size: 4vw
      width: fit-content
      margin: 1rem 1rem 1rem auto

    .btn-agregar
      margin: 0

    .btn-terminos
      width: 45%
      margin-left: auto
      margin-right: 2rem

  .dialogo-nuevo-pago
    width: 80%
    right: auto
    margin-right: 0
    padding: 1rem 0 2rem

  .dialogo-nueva-cuenta
    width: 80%
    left: 10%