.administrar-artista {
  width: 90%;
  margin-top: 3vh;
  position: relative;

  .sin-obras {
    width: 100%;
    text-align: center;
    margin: 5vh 0;
    font-size: 20px;
  }

  .cargando-obras {
    width: 100%;
    text-align: center;
    margin: 5vh 0;
  }
}

.volver-artistas {
  font-size: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#flecha-volver-artistas {
  height: 30px;
  width: 30px;
  margin-right: 1vw;
}

.resumen-artistas-acciones {
  margin: 10vh 0 4vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "foto" "acciones" "datos";
  grid-template-rows: max-content max-content max-content;
  column-gap: 20px;
  justify-items: center;
  @media only screen and (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "foto acciones" "datos datos";
    grid-template-rows: max-content max-content;
  }
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: "foto datos acciones";
    justify-items: center;
  }
}

.acciones {
  grid-area: acciones;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  margin-top: 15px;
  @media only screen and (min-width: 640px) {
    margin-top: 0;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: -50px;
  }
}

.datos-admin-artista {
  grid-area: datos;
  max-width: 80vw;

  .item-dato-admin {
    height: 100%;
    margin-bottom: 15px;
  }
}

.foto-admin-artista {
  grid-area: foto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .nombre-artista {
    text-align: center;
    max-width: 150px;
    overflow-wrap: break-word;
  }

  @media only screen and (max-width: 500px) {
    .nombre-artista {
      max-width: 90px;
    }
  }
}

.borde-gradiente-musico-artista {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: -webkit-linear-gradient(0deg, #e84a88, #55369a);
  filter: drop-shadow(10px 15px 50px rgba(245, 87, 88, 0.5));
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont-img-musico-admin-artista {
  width: 240px;
  height: 240px;
  background: -webkit-linear-gradient(0deg, #e84a88, #55369a);
  border-radius: 50%;
  padding: 15px;
  background-color: white;
  display: grid;
  place-items: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.img-musico-admin-artista {
  height: 72px;
  width: 72px;
}

.datos-regalias-admin {
  overflow: visible;
  display: flex;
  justify-content: space-between;
  margin-top: 1vh;

  .editar-regalias {
    cursor: pointer;
  }

  .input-regalias {
    width: 50%;
  }

  .regalias-modificadas-listas {
    width: 40%;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 15px;
    display: inline-flex;
    align-items: flex-end;
    padding: 0px;
    margin-bottom: -1px;
    justify-content: flex-end;
  }
}

.contenedor-albums-sencillos-admin {
  margin: 2vh 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  row-gap: 2vh;
  justify-items: center;

  .nombre-obra {
    text-align: center;
    word-wrap: break-word;
    max-width: 100px;
  }
}

.item-album-sencillo-administrador {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .img-album-artista {
    width: 150px;
    height: 150px;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }

  .img-sencillo-artista {
    width: 150px;
    height: 150px;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
}

.cont-img-album-sencillo-admin {
  width: 150px;
  height: 150px;
  border: solid #707070 1px;
  padding: 15px;
  background-color: white;
  display: grid;
  place-items: center;
  margin-bottom: 1vh;
  object-fit: cover;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.img-album-sencillo-admin {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.accion-administrar-artista {
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
  transition: 0.2s;
  transform-origin: left;
  @media only screen and (min-width: 640px) {
    color: white;
    text-align: left;
    font-size: 24px;
    margin-top: 20px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.loading-indicator-administrar-artista {
  display: grid;
  place-items: center;
  min-height: 100vh;
}

@media only screen and (max-width: 650px) {
  .acciones {
    margin-bottom: 20px;
  }

  .accion-administrar-artista {
    margin-bottom: 2.5px;
  }
}

@media only screen and (max-width: 500px) {
  .foto-admin-artista {
    justify-self: center;
  }

  .administrar-artista {
    width: 90%;
  }

  .item-dato-admin {
    font-size: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .item-dato-admin {
    font-size: 12px;
  }

  .nombre-artista {
    font-size: 12px;
  }
}
