.detalle-album {
  overflow-y: auto;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Radius */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .input-agregar-album {
    border: none;
    outline: none;
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-bottom: solid 1px black;
    width: 100%;
    max-width: 100%;
  }

  .contenedor-canciones {
    height: 100%;
    padding-left: 17px;
    .item-cancion {
      margin-bottom: 1vh;
      width: 100%;
      position: relative;
      height: fit-content;
      display: list-item;
      align-items: center;

      .delete-icon {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .error-message {
    text-align: center;
  }
}

.titulo-opciones-detalle-album {
  .guardar-agregar-album {
    outline: none;
    grid-area: eliminar;
    justify-self: right;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: -5px;
  }
}

.editar-detalle-album {
  grid-area: editar;
  cursor: pointer;
}

.eliminar-detalle-album {
  grid-area: eliminar;
  justify-self: right;
  cursor: pointer;
}

.item-detalle-album {
  width: 300px;
  margin-top: 20px;
  max-width: 100%;
  border-bottom: 1px solid #e3e3e3;
  > h3 {
    font-size: 18px;
  }
  > span,
  div,
  a {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 5px;
  }
}

.lista-canciones-detalle-album {
  padding-top: 1vh;
  padding-left: 17px;
}

.cancion-detalle-album {
  margin-bottom: 0.5vh;
}

.texto-artista-cancion {
  width: 80%;
}

@media only screen and (max-width: 450px) {
  .titulo-opciones-detalle-album {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "titulo . editar eliminar";
  }
  .guardar-agregar-album {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 300px) {
  .titulo-opciones-detalle-album {
    font-size: 13px;
  }

  .guardar-agregar-album {
    font-size: 13px !important;
    margin-bottom: -4px;
  }
}
