.header-administrador {
    // background-color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 18px 0;
    width: 100%;
    -webkit-box-shadow: 0px -2px 19px 3px rgba(0,0,0,0.64);
    -moz-box-shadow: 0px -2px 19px 3px rgba(0,0,0,0.64);
    box-shadow: 0px -2px 19px 3px rgba(0,0,0,0.64);
    font-size: 17px;
    overflow: hidden;
    // height: 25px;
}

#cerrar-sesion-administrador{
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    cursor: pointer;
}
