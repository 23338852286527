.custom-scroll
    overflow-y: auto
    overflow-x: hidden
    /* width */
    &::-webkit-scrollbar
        width: 4px

    /* Handle */
    &::-webkit-scrollbar-thumb
        background: #888

    /* Radius */
    &::-webkit-scrollbar-thumb
        border-radius: 50px

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
        background: #555
