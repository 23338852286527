.agregar-album-administrador {
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Radius */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .portada-input {
    margin-top: 1vh;
  }

  .contenedor-canciones {
    padding-left: 17px;

    .item-cancion {
      margin-bottom: 1vh;
      width: 100%;
      position: relative;

      .delete-icon {
        position: absolute;
        right: 0;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  .error-message {
    text-align: center;
  }
}

.titulo-agregar-album {
  grid-area: titulo;
}

.item-agregar-album {
  display: flex;
  flex-direction: column;
  font-size: 22px;
  b {
    font-weight: 500;
  }
}

#input-cancion {
  border: none;
}

.input-agregar-album {
  border: none;
  outline: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px #bbb;

  .agregar-cancion-texto {
    border: none;
    width: 44%;
    border-bottom: solid 1px black;
    outline: none;
  }

  #agregar-cancion-artista {
    margin-left: 1vw;
  }

  .agregar-cancion-boton {
    position: absolute;
    height: 100%;
    right: 0;
    cursor: pointer;
  }
}

@media only screen and (max-width: 450px) {
  .titulo-opciones-agregar-album {
    grid-template-areas: "titulo titulo titulo . guardar";
  }
}

@media only screen and (max-width: 300px) {
  .titulo-opciones-agregar-album {
    grid-template-areas: "titulo titulo titulo titulo guardar";
  }
}
