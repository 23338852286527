.fondo-dialogo-administrador {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  place-items: center;
  display: grid;
}

.dialogo-administrador {
  max-width: 80vw;
  max-height: 80vh;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 0px 26px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 26px -8px rgba(0, 0, 0, 0.75);
}
