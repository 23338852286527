#agregar-tema-admin, #icono-editar-admin
    color: white
    border-radius: 50%
    width: 35px
    height: 35px
    // padding: 0vw
    cursor: pointer
    background: linear-gradient(90deg, #F093FB 0%, #F5576C 100%)
    box-shadow: 0px 3px 40px 0px #B3448B66
    transition: transform 0.25s ease-in-out
    &:hover
        transform: scale(1.25)

#icono-editar-admin
    width: 40px
    height: 40px
    padding: 8px

#icono-basura-admin
    width: 30px
    height: 30px
    cursor: pointer
    transition: all 0.25s ease-in-out
    &:hover
        transform: scale(1.25)
