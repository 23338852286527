.pagina-principal-administrador {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.loading-indicator-pagina-administrador {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.content-administrador {
  margin-top: 20px;
  width: 80%;
  justify-self: flex-start;
  @media screen and (max-width: 640px) {
    width: 90%;
  }
}

#add-boton-admin {
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0.25vw;
  cursor: pointer;
  background: linear-gradient(90deg, #f093fb 0%, #f5576c 100%);
  box-shadow: 0px 3px 40px 0px #b3448b66;
}

.fila-agregar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 3vh;
}

.contenedor-musicos-admin {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 8vh;
  justify-items: center;
  margin: 75px 0;
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.borde-gradiente-musico {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: -webkit-linear-gradient(0deg, #e84a88, #55369a);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont-img-musico-admin {
  width: 142px;
  height: 142px;
  border-radius: 50%;
  background-color: white;
  display: grid;
  place-items: center;
  filter: drop-shadow(10px 15px 50px rgba(245, 87, 88, 0.5));
  background-image: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.img-musico-admin {
  height: 80%;
  width: 80%;
}

.item-musico-administrador {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.nombre-item-lista-artistas {
  text-align: center;
  overflow-wrap: break-word;
  font-size: 20px;
  width: 175px;
}

.pago-solicitado {
  font-size: 13px;
}
