.container-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.container-scroll::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.28);
  border-radius: 1rem;
}

.cardCarrusel {
  
}
