:root {
  --coloAzulFuente: #0d294e;
  --colorFuxia: rgb(206, 39, 128);
}

@font-face {
  font-family: MultiItalic;
  src: url(../fonts/Muli-Italic-VariableFont_wght.ttf);
}

@font-face {
  font-family: MultiVariable;
  src: url(../fonts/Muli-VariableFont_wght.ttf);
}

@font-face {
  font-family: Muli;
  src: url(../fonts/Muli.ttf);
}

@font-face {
  font-family: Muli-bold;
  src: url(../fonts/Muli-Bold.ttf);
}

@font-face {
  font-family: BebasNeue;
  src: url(../fonts/BebasNeue.otf);
}

@font-face {
  font-family: NexaBlack;
  src: url(../fonts/NexaBlack.otf);
}

@font-face {
  font-family: NexaLight;
  src: url(../fonts/Nexa-Light.otf);
}

@font-face {
  font-family: NexaBold;
  src: url(../fonts/Nexa-Bold.otf);
}

@font-face {
  font-family: NexaRust;
  src: url(../fonts/NexaRustSans-Black.otf);
}

* {
  /*font-family: ;*/
  margin: 0;
  padding: 0;
  /* color: var(--color0); */
}

.textoBlanco {
  color: white;
}

.textoAzul {
  color: var(--coloAzulFuente);
}

.titulo-grande {
  font-size: 5vw;
  font-weight: bold;
  font-family: NexaBlack;
  text-shadow: 1px 1px black;
}

.titulo-semi-grande {
  font-size: 4.5vw;
  font-weight: bold;
  font-family: NexaBlack;
  text-shadow: 1px 1px black;
}

.texto-descripcion {
  font-size: 2vw;
  font-family: MultiItalic;
  text-shadow: 1px 1px black;
}

.texto-servicio {
  font-size: 2vw;
  font-family: MultiVariable;
}

.texto-servicio2 {
  font-size: 2vw;
  font-family: MultiVariable;
}

.botonRosa {
  background: var(--colorFuxia);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: MultiVariable;
  font-weight: bold;
  text-decoration: none;
  font-size: 2.2vw;
  width: 30%;
  height: 7vw;
  border-radius: 5vw;
}

.botonRosa2 {
  background: var(--colorFuxia);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: MultiVariable;
  font-weight: bold;
  text-decoration: none;
  font-size: 2.2vw;
  width: 30%;
  height: 7vw;
  border-radius: 5vw;
}

.textoInicio {
  font-size: 3.3vw;
  font-family: NexaBlack;
}

.letraFoot {
  font-size: 1.5vw;
  text-decoration: none;
}

.letraFootDerechos {
  font-size: 1.3vw;
  font-family: MultiVariable;
}

.letraOpcionMenu {
  font-size: 2.5vw;
  font-family: NexaBlack;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .titulo-grande {
    font-size: 8vw;
    margin: 0 5%;
  }

  .titulo-semi-grande {
    font-size: 7.5vw;
    margin: 0 5%;
  }

  .texto-descripcion {
    font-size: 4vw;
    margin: 0 5%;
  }

  .texto-servicio {
    font-size: 4vw;
  }

  .texto-servicio2 {
    font-size: 3.5vw;
  }

  .letraFoot {
    font-size: 2.5vw;
  }

  .letraFootDerechos {
    font-size: 2.5vw;
  }

  .letraOpcionMenu {
    font-size: 6vw;
  }

  .botonRosa2 {
    font-size: 4.5vw;
    width: 60%;
    height: 13vw;
    border-radius: 10vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
}

body > iframe {
  display: none !important;
}