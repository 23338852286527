@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Proxima Nova;
  }
  body {
    @apply bg-[#090930] text-white;
    @apply relative;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  .btn--primary {
    @apply w-[210px] h-[50px] text-base font-medium rounded-md py-2.5 px-5 bg-[#E84A84] text-white;
  }

  .btn--header {
    @apply text-sm sm:text-sm md:text-base px-10 py-3 rounded-md  text-white hover:shadow-button transition-all duration-200 ease-in-out;
  }

  .btn--secondary {
    @apply text-sm sm:text-sm md:text-base px-10 py-3 rounded-md  text-white hover:shadow-button transition-all duration-200 ease-in-out;
  }
  .gradient {
    background: linear-gradient(90deg, #e84a88 -8.03%, #55369a 142.31%);
  }

  .btn--secondary-sm {
    @apply text-xs sm:text-sm px-10 py-3 font-normal rounded-[4px]  bg-gradient-to-l from-[#36369A] to-[#E84A84] text-white hover:shadow-button transition-all duration-200 ease-in-out;
  }
  .btn--navbar {
    @apply text-xs sm:text-sm px-10 py-3  font-normal rounded-[4px]  text-white hover:shadow-button transition-all duration-200 ease-in-out;
  }
  .btn--primary-sm {
    @apply text-sm font-medium rounded-md py-2 px-4 bg-[#E84A84] text-white;
  }
  .btn--primary-sm-outline {
    @apply text-sm font-medium rounded-md py-2 px-4 border border-[#E84A84] text-rosa-primariy;
  }

  .input-admin {
    @apply bg-transparent border-b-2 outline-none resize-none;
  }
  .input-admin::-webkit-scrollbar {
    width: 4px;
  }
  .input-admin::-webkit-scrollbar-track {
    background: transparent;
  }
  .input-admin::-webkit-scrollbar-thumb {
    background: #e84a84;
    border-radius: 100px;
  }
  .texto-inferior {
    @apply placeholder-inferior;
  }
  .placeholder-inferior::placeholder {
    position: absolute;
    bottom: 7px;
    left: 7px;
  }
  .animacion-iconos {
    transition: all 0.25s ease-in-out;
  }
  .animacion-iconos:hover {
    transform: scale(1.25);
  }
  .scrollbar-artista::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: rgba(232, 74, 132, 0.2);
  }
  .scrollbar-thumb::-webkit-scrollbar-thumb {
    background: rgba(232, 74, 132, 0.9);
    border-radius: 2rem;
  }
  .fondo-tap-highlight {
    -webkit-tap-highlight-color: transparent;
  }
  .number-input {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.btn-file {
  position: relative;
  padding: 10px 25px;
  font-size: 16px;
  border-radius: 5px;
  color: white;
  background: #e84a84;
}

.btn-file:hover {
  background: #f46297;
}

.btn-file input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* input[type="radio"]:checked {
  color: red;
  box-shadow: 0 0 0 3px orange;
  cursor: pointer;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 8px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #e84a84;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
iframe {
  display: none;
}
