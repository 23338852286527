.fondo-administrador {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(144.82deg, #5d2d8c 0%, #090930 100%);

  .loading-admin {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}

.field-toggle-obra {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  margin: 10px 0;
  span {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin-right: 10px;
  }
}
