// hero
.image-girl {
  width: 150px;
}

.image-speaker {
  width: 80px;
}

.image-folder {
  width: 90px;
  height: 90px;
}

// cards
.cards-container {
  justify-content: space-around;
}

.card {
  background: linear-gradient(
    137.82deg,
    rgba(255, 255, 255, 0.7) -11.81%,
    rgba(255, 255, 255, 0.15) 107.94%
  );
  box-shadow: 0px 4px 25px rgba(194, 194, 255, 0.25);
}

// Seccion Ganar diner

.girl-phone {
  width: 125px;

  @media (min-width: 768px) {
    width: 200px;
  }
}

.coins {
  width: 100px;
  height: 110px;

  @media (min-width: 768px) {
    width: 140px;
    height: 150px;
    margin-left: 1rem;
  }
}

// audience

.card-audience {
  // width: 140px;
  // height: 140px;
  background: linear-gradient(
    137.82deg,
    rgba(255, 255, 255, 0.7) -11.81%,
    rgba(255, 255, 255, 0.15) 107.94%
  );
  box-shadow: 0px 4px 25px rgba(194, 194, 255, 0.25);
  border-radius: 10px;

  @media (min-width: 768px) {
    // width: 205px;
    // height: 205px;
  }
}
