.detalle-sencillo {
  overflow-y: auto;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Radius */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .input-agregar-sencillo {
    border: none;
    outline: none;
    position: relative;
    display: flex;
    justify-content: flex-start;
    border-bottom: solid 1px black;
    width: 100%;
  }
}

.titulo-opciones-detalle-sencillo {
  .guardar-agregar-sencillo {
    outline: none;
    grid-area: eliminar;
    justify-self: right;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: -5px;
  }
}

.editar-detalle-sencillo {
  grid-area: editar;
  cursor: pointer;
}

.eliminar-detalle-sencillo {
  grid-area: eliminar;
  justify-self: right;
  cursor: pointer;
}

.item-detalle-sencillo {
  width: 300px;
  margin-top: 20px;
  max-width: 100%;
  border-bottom: 1px solid #e3e3e3;
  > h3 {
    font-size: 18px;
  }
  > span,
  div,
  a {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 5px;
  }
}

.lista-canciones-detalle-sencillo {
  padding-top: 1vh;
  padding-left: 0.5vw;
}

.cancion-detalle-sencillo {
  margin-bottom: 0.5vh;
}

.area-sencillo {
  grid-area: titulo;
}

@media only screen and (max-width: 450px) {
  .titulo-opciones-detalle-sencillo {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "titulo  . editar eliminar";
    font-size: 15px;
  }
  .guardar-agregar-sencillo {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 300px) {
  .titulo-opciones-detalle-sencillo {
    font-size: 13px;
  }

  .guardar-agregar-sencillo {
    font-size: 13px !important;
    margin-bottom: -4px;
  }
}
