.contenedor-radio-curso
  position: relative
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  & input
    position: absolute
    opacity: 0
    cursor: pointer
    width: 100%
    height: 100%
    z-index: 1

.checkmark
  position: absolute
  bottom: 15px
  right: 0
  height: 20px
  width: 20px
  border: 1px solid white
  border-radius: 50%

.contenedor-radio-curso:hover input ~ .checkmark
  background-color: #FFF

.contenedor-radio-curso input:checked ~ .checkmark
  background-color: #E84A84

.contenedor-radio-curso .checkmark:after
  top: 9px
  left: 9px
  width: 8px
  height: 8px
  border-radius: 50%
  background: white
